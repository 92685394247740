import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es'
  },
  theme: {
    themes: {
      light: {
        primary: '#4C79AF',
        secondary: '#424242'
      }
    }
  }
});
