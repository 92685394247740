<template>
    <v-container class="my-10">
      <v-row>
        <v-col v-for="plan in plans" :key="plan.id" cols="12" md="4">
          <v-card class="mx-auto" max-width="400" >
            <v-card-title>{{ plan.title }}</v-card-title>
            <v-card-text>
              <div class="display-1">₡ {{ plan.price }}/mo</div>
              <v-card-text>{{ plan.description }}</v-card-text>
              <ul>
                <li v-for="feature in plan.features" :key="feature">{{ feature }}</li>
              </ul>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" :href="plan.cta.link" v-if="false">{{ plan.cta.text }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'Plans',
    data() {
      return {
        plans: [
          {
            id: 1,
            title: "Plan Servicios",
            description: 'Recomendado para negocios de servicios por ejemplo salones de belleza, profesionales independientes, entre otros.',
            price: 7910,
            features: ["Hasta 3 usuarios", "Gastos de administración y ventas"],
            cta: {
              text: "Comprar",
              link: "/comprar/basico"
            }
          },
          {
            id: 2,
            title: "Plan Comercial",
            description: 'Recomendado para negocios como tiendas o supermercados.',
            price: 22600,
            features: ["Hasta 5 usuarios", "Inventarios de mercaderia", "Gastos y Costos de mercaderia vendida"],
            cta: {
              text: "Comprar",
              link: "/comprar/estandar"
            }
          },
          {
            id: 3,
            title: "Plan Productivo",
            description: 'Recomendado para negocios como fabricas, procesamiento de alimentos, entre otros.',
            price: 45200,
            features: ["Hasta 7 usuarios", "Inventarios de mercaderia y materias primas", "Gastos y costos de producción"],
            cta: {
              text: "Comprar",
              link: "/comprar/premium"
            }
          }
        ]
      };
    }
  };
  </script>
  