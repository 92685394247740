<template>
  <v-container fluid class="pa-0">
    <Headline></Headline>
    <Modules></Modules>
    <Benefits></Benefits>
    <Plans></Plans>
    <Demo></Demo>
  </v-container>
</template>

<script>
// @ is an alias to /src
import Headline from '@/components/Headline.vue'
import Plans from '@/components/Plans.vue'
import Benefits from '@/components/Benefits.vue'
import Modules from '@/components/Modules.vue'
import Demo from '@/components/Demo.vue'

export default {
  name: 'Home',
  components: {
    Headline, 
    Plans, 
    Benefits,
    Modules,
    Demo
  }
}
</script>
