<template>
  <v-container fluid style="height: 100vh;">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6>
        <v-card class="elevation-0">
          <v-card-title>
            <v-layout align-center justify-center>
              <h2>Bienvenido a RedaBits</h2>
            </v-layout>
          </v-card-title>
          <v-card-text>
            <p>¡Felicitaciones! Tu cuenta ha sido creada exitosamente en RedaBits.</p>
            <p>Empieza a gestionar tu empresa y aprovecha al máximo todas nuestras características y herramientas.</p>
          </v-card-text>
          <v-card-actions>
            <v-layout align-center justify-center>
              <v-btn color="primary" dark href="https://app.redabitscr.com">
                Ir al Panel de Control
              </v-btn>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
  
<script>
export default {
  name: 'AccountCreatedView',
}
</script>
  