<template>
  <div>
    <v-progress-circular
      :size="100"
      color="primary"
      indeterminate
      v-if="loading"
    ></v-progress-circular>
    <v-form @submit.prevent="submitForm" ref="form" v-else>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field prepend-inner-icon="mdi-credit-card" outlined v-model="cardNumber" label="Número de Tarjeta" :rules="cardNumberRules"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field prepend-inner-icon="mdi-lock" outlined v-model="cvv" label="Código de Seguridad" placeholder="CVV" :rules="cvvRules"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field prepend-inner-icon="mdi-calendar-month" outlined v-model="expMonth" label="Mes de Expiración" placeholder="MM" :rules="expMonthRules"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field outlined v-model="expYear" prepend-inner-icon="mdi-calendar-month"  label="Año de Expiración" placeholder="AAAA" :rules="expYearRules"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field outlined v-model="holderName" label="Nombre del Titular" :rules="holderNameRules"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="!hideSubmit">
          <v-col cols="12">
            <v-btn type="submit" color="primary" :disabled="!formIsValid">Pagar</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'

export default {
  name: 'CreditCard',
  props: {
    hideSubmit: Boolean
  },
  data () {
    return {
      cardNumber: '',
      expMonth: '',
      expYear: '',
      cvv: '',
      holderName: '',
      loading: false
    }
  },
  mounted () {
    this.$refs.form.resetValidation()
  },
  computed: {
    payload () {
      return {
        cardNumber: this.cardNumber,
        expMonth: this.expMonth,
        expYear: this.expYear,
        cvv: this.cvv,
        holderName: this.holderName
      }
    },
    formIsValid () {
      // Validar que todos los campos estén completos
      return this.$refs.form.validate()
    },
    cardNumberRules () {
      return [
        value => !!value || 'El número de tarjeta es obligatorio',
        value => (value && value.length === 16) || 'El número de tarjeta debe tener 16 dígitos'
      ]
    },
    expMonthRules () {
      return [
        value => !!value || 'El mes de expiración es obligatorio',
        value => (value && value.length === 2 && parseInt(value) >= 1 && parseInt(value) <= 12) || 'El mes de expiración es inválido'
      ]
    },
    expYearRules () {
      return [
        value => !!value || 'El año de expiración es obligatorio',
        value => (value && value.length === 4 && parseInt(value) >= new Date().getFullYear()) || 'El año de expiración es inválido'
      ]
    },
    cvvRules () {
      return [
        value => !!value || 'El código de seguridad es obligatorio',
        value => (value && value.length === 3) || 'El código de seguridad debe tener 3 dígitos'
      ]
    },
    holderNameRules () {
      return [
        value => !!value || 'El nombre del titular es obligatorio'
      ]
    }
  },
  methods: {
    submitForm () {
      this.loading = true
      this.$emit('submitPay')
      // Construir el objeto de payload con los datos del formulario
      const payload = {
        card: {
          number: this.cardNumber,
          expMonth: this.expMonth,
          expYear: this.expYear,
          cvv: this.cvv,
          holderName: this.holderName
        },
        key: this.$route.query.key
      }

      // Enviar la solicitud POST al backend con Axios
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/pay-voucher`, payload)
        .then(response => {
          // Manejar la respuesta del backend
          console.log(response.data)
          // this.$emit('submited', response.data)
          location.reload()
        })
        .catch(error => {
          // Manejar errores de la solicitud
          console.error(error)
          this.showError('Error al procesar pago, intentar más tarde')
          this.$emit('submitError', error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    ...mapActions('message', [
      'showSuccess',
      'showError'
    ])
  }
}
</script>
