<template>
  <v-container class="my-10">
    <v-row>
      <v-col cols="12" sm="6" class="text-center">
        <v-img :src="require('@/assets/demo.jpg')" max-height="700"></v-img>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex align-center justify-center">
        <v-row>
          <v-col cols="12" class="text-center">
            <h2 class="display-1">¿Quieres probar redaBits?</h2>
            <h2 class="display-1">Solicita ahora mismo una demostración</h2>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn x-large color="primary" href="https://calendly.com/redabits/presentacion-de-soluciones" target="_blank">agendar demo</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
  </template>
  
  <script>
  export default {
    name: 'Demo',
  }
  </script>
  
  <style scoped>
  h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  v-img {
    max-width: 100%;
  }
  </style>
  