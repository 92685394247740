<template>
  <v-img :src="require('@/assets/headline.jpg')" max-height="700">
    <v-container>
      <v-row>
        <v-col>
        </v-col>
        <v-col  class="text-right d-flex align-end flex-column" style="height: 90vh;">
          <div class="pa-2 mt-auto">
            <v-img :src="require('@/assets/logoRedabits.png')" max-width="400" class="mt-auto"></v-img>
          </div>
          <div class="pa-2 mt-auto">
            <h1 class="display-2 mb-6">Lleva su emprendimiento a un nuevo nivel</h1>
            <p class="subtitle-1 mb-10">Transforma la gestión de su negocio a un precio accesible. Con redaBits podra alcanzar su máximo potencial empresarial.</p>
            <v-btn text color="secondary" dark large href="https://app.redabitscr.com" target="_blank">Ya tengo una cuenta</v-btn>
            <v-btn color="primary" to="/signup" dark large>¡Regístrate ahora!</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-img>
</template>
  
<script>
export default {
  name: 'Headline',
}
</script>
  
<style>
.my-sheet {
  background-size: cover;
  background-position: center;
}
</style>
  