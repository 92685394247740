<template>
    <v-container class="my-10">
      <!--<h2>Los módulos de nuestro software de gestión empresarial incluyen:</h2>-->
      <v-row>
        <v-col cols="12" md="4">
          <h2>¿Por qué usar redaBits es la mejor opción para hacer crecer mi negocio?</h2>
        </v-col>
        <v-col cols="12" md="4" v-for="(item, i) in modules" :key="i">
          <v-sheet elevation="4" rounded="lg" class="text-center">
            <v-card-text><v-icon x-large color="primary">{{ item.icon }}</v-icon></v-card-text>
            <v-card-text class="title">{{ item.title }}</v-card-text>
            <v-card-text class="body-2">
              {{ item.text }}
            </v-card-text>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'Modules',
    data: () => ({
      modules: [
        {title: 'No más problemas con Hacienda', text: 'Cumplimos con todos los requisitos del Ministerio de Hacianda. Ademas generamos automaticamente las declaraciones fiscales.', icon:'mdi-file-document-alert-outline'},
        {title: 'Su información segura en la nube', text: 'Toda su información integrada, segura y accesible en todo momento y sin importar el lugar', icon:'mdi-cloud-lock-outline'},
        {title: 'Ahorra tiempo y dinero', text: 'Con redaBits ya no tendras que gastar gran cantidad de tiempo ni recursos en tareas reptitivas.', icon:'mdi-timer-plus-outline'},
        {title: '¡Toma el control!', text: 'Conoce el estado de su negocio en tiempo real. No tendras que esperar a fin de mes para tener informes detallados.', icon:'mdi-account-lock'},
        {title: 'Dirige su negocio adonde usted quiera', text: 'Te presentamos la información de forma simple y relevante para la toma de decisiones estrategicas.', icon:'mdi-strategy'},
      ]
    })
  }
  </script>
  
  <style scoped>
  h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  
  v-card {
    margin-bottom: 20px;
  }
  </style>
  