<template>
    <v-container class="my-10">
      <v-row>
        <v-col cols="12" md="6">
          <h2>Todo lo que ocupas para emprender en un solo lugar</h2>
          <ul>
            <li>Ventas, compras y gastos</li>
            <li>Gestión de inventarios</li>
            <li>Control de costos</li>
            <li>Cuentas por cobrar y pagar</li>
            <li>Registro de activos fijos y devaluación</li>
            <li>Contabilidad completamente automatizada</li>
            <li>Informes en tiempo real enfocados en la toma de decisiones</li>
          </ul>
        </v-col>
        <v-col cols="12" md="6">
          <v-img src="../assets/benefits.jpg" alt="Beneficios" />
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'Benefits',
  }
  </script>
  
  <style scoped>
  h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  v-img {
    max-width: 100%;
  }
  </style>
  