<template>
  <!-- App.vue -->
  <v-app>
    <v-app-bar app :inverted-scroll="$route.name === 'Home'" v-if="$route.name !== 'SignUp' && $route.name !== 'Finish'">
      <v-toolbar-title>
        <router-link to="/">
          <v-img
            :src="require('@/assets/logo.png')"
          />
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text
        class="ma-1"
        v-for="(item, i) in items"
        :key="i"
        :to="item.link"
      >
        {{ item.title }}
      </v-btn>
      <v-btn outlined
      href="https://app.redabitscr.com"
      target="_blank"
      class="ma-1">
        Iniciar sesión
      </v-btn>
      <v-btn
        depressed
        color="primary"
        class="ma-1"
        to="/signup"
      >
        Crear cuenta
      </v-btn>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- Provides the application the proper gutter -->
      <v-container fluid class="pa-0">
        <router-view  id="main"></router-view>
        <v-speed-dial
          v-model="fab"
          bottom
          right
          :direction="'left'"
          open-on-hover
          fixed
          :transition="'slide-x-reverse-transition'"
        >
          <template v-slot:activator>
            <v-btn
              v-model="fab"
              color="blue darken-2"
              dark
              :fab="!fab"
              :x-large="fab"
              :rounded="fab"
              href="https://wa.me/50685840347"
              target="_blank"
            >
              <template  v-if="fab">                
                Contacta un asesor
                <v-icon
                  right
                  dark
                  large
                >
                  mdi-whatsapp
                </v-icon>
              </template>
              <v-icon v-else>
                mdi-whatsapp
              </v-icon>
            </v-btn>
          </template>
        </v-speed-dial>
      </v-container>
    </v-main>

    <v-footer app class="secondary white--text" absolute>
      <v-card class="secondary white--text" width="100%" flat>

        <v-card-text class="white--text pt-0"></v-card-text>

        <v-card-text class="white--text pt-0">
          <v-row>
            <v-col cols="12" md="6" >
              <v-list dense class="secondary" v-if="false">
                <v-list-item v-for="(item, i) in items" :key="i" link :href="item.link">
                  <v-list-item-title class="white--text">{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="6">
              <div class="ma-4 text-right">
                <a class="subtitle-1 ma-2" href="tel:+50685840347">+506 8584 0347</a>
                <p class="subtitle-1 ma-2">San José, Costa Rica</p>
                <v-btn
                  v-for="{icon, link} in icons"
                  :key="icon"
                  class="white--text"
                  icon
                  :href="link"
                  target="_blank"
                >
                  <v-icon size="24px">
                    {{ icon }}
                  </v-icon>
                </v-btn>
                <div class="d-flex justify-end ma-2">
                  <v-img max-width="175" :src="require('@/assets/logoRedabitsBlanco.png')" class="align-end"></v-img>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text text-center">
          © {{ new Date().getFullYear() }} — <strong>redaBits</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data: () => ({
    fab: false,
    items: [
      { title: 'Inicio', link: '/' },
      { title: 'Acerca de', link: '/about' },
      { title: 'Servicios', link: '/services' },
      { title: 'Precios', link: '/prices' },
      { title: 'Contacto', link: '/contact' },
    ],
    icons: [
      {icon:'mdi-facebook', link: 'https://www.facebook.com/redaBitsCR/'},
      {icon:'mdi-instagram', link: 'https://www.instagram.com/redabits/'},
      {icon:'mdi-whatsapp', link: 'https://wa.me/50685840347'},
    ],
  }),
};
</script>
