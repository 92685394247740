<template>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2 class="text-center">Contáctenos</h2>
          <p class="text-center">¿Tiene alguna pregunta o comentario? Por favor, rellene el formulario de contacto a continuación y nos pondremos en contacto con usted lo antes posible.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <h3 class="text-center">Información de contacto</h3>
          <v-row>
            <v-col cols="12" md="6" class="text-center">
              <v-icon size="48" class="mb-2">mdi-whatsapp</v-icon>
              <p class="mb-2">+506 8584-0347</p>
            </v-col>
            <v-col cols="12" md="6" class="text-center">
              <v-icon size="48" class="mb-2">mdi-map-marker</v-icon>
              <p class="mb-2">San José, Costa Rica</p>
            </v-col>
            <v-col cols="12" class="text-center">
              <h3 class="text-center">Solicita una demostración</h3>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn large color="primary" href="https://calendly.com/redabits/presentacion-de-soluciones" target="_blank">agendar demo</v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card>
            <v-card-text>
              <v-form @submit.prevent="submitForm">
                <v-text-field v-model="name" label="Nombre" required></v-text-field>
                <v-text-field v-model="email" label="Correo Electrónico" required></v-text-field>
                <v-textarea v-model="message" label="Mensaje" required></v-textarea>
                <v-btn type="submit" color="primary">Enviar</v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    data() {
      return {
        name: "",
        email: "",
        message: "",
      };
    },
    methods: {
      submitForm() {
        // Código para enviar el formulario
        console.log("Formulario enviado");
        // Limpiar el formulario
        this.name = "";
        this.email = "";
        this.message = "";
      },
    },
  };
  </script>
  